<template>
  <div class="section">
    <div class="container">
      <!-- Page Header -->
      <div class="mb-lg">
        <div class="columns is-vcentered">
          <div class="column is-one-third">
            <breadcrumbs :history="[{ label: 'Orders', name: 'orders.dashboard'}]" current="Cancels" />
          </div>
          <div class="column is-one-third">
            <h1 class="has-text-centered is-size-4">Cancels</h1>
          </div>
        </div>
      </div>
      <!-- Filters Toolbar -->
      <div class="has-background-white rounded-lg pa-md mb-xxs">
        <div class="columns">
          <div class="column">
            <cancel-filters :applied-filters="filters" @filter="filter" />
          </div>
          <div class="column is-narrow">
            <b-select v-model="orderBy" size="is-medium" expanded @input="sort">
              <option value="-id">Most Recent</option>
              <option value="id">Oldest</option>
            </b-select>
          </div>
          <div class="column is-narrow">
            <b-button size="is-medium" class="is-fullwidth" icon-left="download" @click="showExportModal = !showExportModal">Export</b-button>
          </div>
        </div>
      </div>
      <!-- Actions Toolbar -->
      <div class="has-background-white rounded-lg px-md py-sm mb-xxs">
        <div class="columns is-vcentered is-mobile">
          <div class="column is-narrow">
            <b-dropdown :disabled="loading || selected.length === 0">
              <button class="button" slot="trigger" slot-scope="{ active }" :disabled="selected.length === 0">
                <span>Actions</span>
                <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
              </button>
              <b-dropdown-item has-link><a href="#" @click.prevent="showAcceptCancelsModal = true">Acknowledge Cancels</a></b-dropdown-item>
            </b-dropdown>
          </div>
          <div class="column">
            <div class="has-text-right is-size-7">
              <!-- Counts -->
              <p v-if="loading">Loading...</p>
              <p v-else>{{ total }} cancels found.</p>
            </div>
          </div>
        </div>
      </div>
      <!-- Results -->
      <div>
        <div class="mb-md">
          <b-table
            :data="results"
            :checked-rows.sync="selected"
            :mobile-cards="false"
            :loading="loading"
            hoverable
            checkable
            checkbox-position="left"
            class="is-size-7">
            <template>
              <!-- RC ID (since RMA Number not always required) -->
              <b-table-column
                label="ID"
                header-class="nowrap"
                cell-class="nowrap"
                width="98"
                v-slot="props">
                <a href="#"
                  class="has-text-weight-bold has-text-info"
                  @click.prevent="inspectCancel = props.row"
                >{{ props.row.id }}</a>
              </b-table-column>
              <!-- PO # -->
              <b-table-column
                label="PO #"
                header-class="nowrap"
                cell-class="nowrap"
                width="128"
                v-slot="props">
                <a href="#"
                  class="has-text-weight-bold has-text-info"
                  @click.prevent="showOrder(props.row)"
                >{{ props.row.purchase_order_number }}</a>
              </b-table-column>
              <!-- Brand -->
              <b-table-column
                v-if="context.isMerchant()"
                label="Supplier"
                header-class="nowrap"
                cell-class="nowrap"
                v-slot="props">
                {{ props.row.brand.name }}
              </b-table-column>
              <!-- Merchant -->
              <b-table-column
                v-if="context.isSupplier()"
                label="Merchant"
                header-class="nowrap"
                cell-class="nowrap"
                width="255"
                v-slot="props">
                {{ props.row.retailer.name }}
              </b-table-column>
              <!-- Brand -->
              <b-table-column
                label="Orginator"
                header-class="nowrap"
                cell-class="nowrap"
                v-slot="props">
                <p v-if="props.row.canceled_by === 'retailer'">{{ props.row.retailer.name }}</p>
                <p v-else>{{ props.row.brand.name }}</p>
              </b-table-column>
              <!-- Reason -->
              <b-table-column
                label="Reason"
                header-class="nowrap"
                cell-class="wrap"
                v-slot="props">
                {{ props.row.reason }}
              </b-table-column>
              <!-- Registered -->
              <b-table-column
                label="Registered"
                header-class="nowrap"
                cell-class="nowrap"
                centered
                width="128"
                v-slot="props">
                <span v-if="props.row.canceled_at === null">--</span>
                <span v-else>{{ $dates.utc(props.row.canceled_at).local().format('MMM D, YYYY') }}</span>
              </b-table-column>
              <!-- Acknowledged -->
              <b-table-column
                label="Acknowledged"
                header-class="nowrap"
                cell-class="nowrap"
                centered
                width="128"
                v-slot="props">
                <span v-if="props.row.acknowledged_at === null">--</span>
                <span v-else>{{ $dates.utc(props.row.acknowledged_at).local().format('MMM D, YYYY') }}</span>
              </b-table-column>
            </template>
            <template #empty>
              <div class="py-xxl has-text-grey has-text-centered">
                {{ (loading) ? "Loading data..." : 'No cancels found!' }}
              </div>
            </template>
          </b-table>
        </div>
        <b-pagination
          :total="total"
          :current.sync="currentPage"
          :rounded="true"
          :per-page="perPage"
          @change="paginate">
        </b-pagination>
      </div>

      <!-- Inspect Cancel -->
      <b-modal
        v-if="(inspectCancel !== null)"
        has-modal-card
        :active="(inspectCancel !== null)"
        :can-cancel="['escape', 'x']"
        @close="closeModals"
        ><CancelModal :selectedCancel="inspectCancel" />
      </b-modal>

      <!-- Inspect Order -->
      <b-modal
        has-modal-card
        scroll="keep"
        :active.sync="showOrderModal"
        :can-cancel="['x']"
        @close="closeModals"
        ><OrderModal :selectedOrder="inspectOrder" />
      </b-modal>

      <!-- Bulk Accept -->
      <b-modal
        has-modal-card
        scroll="keep"
        :active.sync="showAcceptCancelsModal"
        :can-cancel="['x']"
        ><AcceptCancelsModal :cancels="selected" @reload="reload" />
      </b-modal>

      <!-- Modal: Export  -->
      <b-modal
        has-modal-card
        scroll="keep"
        :active.sync="showExportModal"
        :can-cancel="['x']"
        ><div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">Export Cancels</p>
          </header>
          <section class="modal-card-body">
            <p class="pa-xl has-text-grey has-text-centered">
              Click 'Export' below to have an invoices export delivered to your
              email. Please note that the export will only include
              the items that match your applied filters. Please double check
              your filters before exporting.
            </p>
          </section>
          <footer class="modal-card-foot" style="justify-content:flex-end">
            <b-button type="is-primary" @click="scheduleExport" :loading="exporting" :disabled="exporting">Export</b-button>
          </footer>
        </div>
      </b-modal>

    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import presets from '../presets/cancels'
import Filterable from '@/mixins/Filterable'
import Exportable from '@/mixins/Exportable'
import CancelModal from '@/components/Modals/Cancel/CancelModal'
import CancelFilters from '../components/Cancels/CancelFilters'
import OrderModal from '@/components/Modals/Order/OrderModal'
import AcceptCancelsModal from '../components/Cancels/AcceptCancelsModal'

export default {
  components: {
    CancelFilters,
    CancelModal,
    OrderModal,
    AcceptCancelsModal
  },
  data () {
    return {
      key: 'cancels',
      alwaysAttach: {
        short: 1,
        skip_attributes: 1
      },
      // presets
      presets: presets,
      // modals
      showOrderModal: false,
      showCancelModal: false,
      showAcceptCancelsModal: false,
      inspectCancel: null,
      inspectOrder: null
    }
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return this.context.route + '/cancels/'
    },
    exportEndpoint () {
      return this.endpoint + 'email-export/'
    }
  },
  methods: {
    closeModals () {
      this.inspectCancel = null
      this.inspectOrder = null
    },
    showOrder (cancel) {
      this.inspectOrder = {
        id: cancel.order_id,
        purchase_order_number: cancel.purchase_order_number
      }
      this.showOrderModal = true
    },
    reload () {
      this.selected = []
      this.load()
    }
  },
  mounted () {
    this.load()
  },
  mixins: [Filterable, Exportable]
}
</script>
