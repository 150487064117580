<template>
  <div>
    <div class="section">
      <div class="container">
        <!-- Page Header -->
        <div class="mb-sm">
          <div class="columns is-centered">
            <div class="column is-one-third">
              <h1 class="has-text-centered is-size-4">Orders</h1>
            </div>
          </div>
          <!-- <div v-if="context.isMerchant() && !context.partner.isOnboarded">
            <InfoBanner bannerType="is-warning"/>
          </div> -->
        </div>
        <!-- Content -->
        <div class="mb-lg">
          <div class="columns is-centered">
            <div class="column is-full-tablet is-four-fifths-widescreen">
              <!-- Order Search -->
              <div class="rounded-lg has-background-white mb-lg pa-md">
                <div class="columns is-vcentered">
                  <div class="column">
                    <order-search />
                  </div>
                  <div v-if="context.isMerchant()" class="column is-narrow">
                    <a href="#" @click.prevent="showAdvancedSearchModal = true">Advanced</a>
                  </div>
                  <div v-if="context.isSupplier()" class="column is-narrow">
                    <b-dropdown expanded position="is-bottom-left">
                      <button class="button is-medium is-fullwidth" slot="trigger">
                        <b-icon icon="application-import"></b-icon>
                        <span>Import</span>
                      </button>
                      <b-dropdown-item has-link>
                        <a href="#" @click.prevent="importFulfillemts('shipments')">Import Tracking Numbers</a>
                      </b-dropdown-item>
                      <b-dropdown-item has-link>
                        <a href="#" @click.prevent="importFulfillemts('invoices')">Import Invoices</a>
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                  <div class="column is-narrow">
                    <b-dropdown expanded position="is-bottom-left">
                      <button class="button is-medium is-fullwidth" slot="trigger">
                        <b-icon icon="dots-vertical"></b-icon>
                        <span>More</span>
                      </button>
                      <template v-if="context.isSupplier()">
                        <b-dropdown-item has-link>
                          <a href="#" @click.prevent="importFulfillemts('shipments')">Import Tracking Numbers</a>
                        </b-dropdown-item>
                        <b-dropdown-item has-link>
                          <a href="#" @click.prevent="importFulfillemts('invoices')">Import Invoices</a>
                        </b-dropdown-item>
                        <hr class="dropdown-divider">
                      </template>
                      <template v-if="context.isSupplier() && auth.isStaff">
                        <b-dropdown-item has-link>
                          <router-link :to="{ name: 'orders.utilities' }">Order Utilities</router-link>
                        </b-dropdown-item>
                        <hr class="dropdown-divider">
                      </template>
                      <b-dropdown-item has-link>
                        <router-link :to="{ name: 'shipments.list' }">Review Tracking #s</router-link>
                      </b-dropdown-item>
                      <b-dropdown-item has-link>
                        <router-link :to="{ name: 'invoices.list' }">Review Invoices</router-link>
                      </b-dropdown-item>
                      <b-dropdown-item has-link>
                        <router-link :to="{ name: 'cancels.list' }">Review Cancels</router-link>
                      </b-dropdown-item>
                      <b-dropdown-item has-link>
                        <router-link :to="{ name: 'returns.list' }">Review Returns</router-link>
                      </b-dropdown-item>
                      <b-dropdown-item has-link>
                        <router-link :to="{ name: 'credits.list' }">Review Credit Memos</router-link>
                      </b-dropdown-item>
                      <template v-if="!context.isSupplier()">
                        <hr class="dropdown-divider">
                        <b-dropdown-item has-link>
                          <router-link :to="{ name: 'orders.create' }">Create Purchase Order</router-link>
                        </b-dropdown-item>
                      </template>
                    </b-dropdown>
                  </div>
                </div>
              </div>
              <!-- Order Browser -->
              <div class="columns">
                <div class="column">
                  <div class="mb-lg">
                    <p class="has-text-weight-bold has-text-centered mb-md">Open Orders</p>
                    <OrderAging />
                  </div>
                  <div class="mb-lg">
                    <p class="has-text-weight-bold has-text-centered mb-md">Require Attention</p>
                    <OrderAttention />
                  </div>
                </div>
                <div class="column">
                  <p class="has-text-weight-bold has-text-centered mb-md">Reports</p>
                  <div class="has-background-white rounded-lg pa-lg">
                    <!-- Messaging -->
                    <router-link :to="{ name: 'orders.list', query: { preset: 'unread' } }">
                      <div class="mb-xs">
                        <div class="columns is-vcentered is-mobile">
                          <div class="column">
                            <div class="">
                              <h4 class="has-text-weight-bold">Orders with New Messages</h4>
                              <p class="has-text-grey is-size-7">
                                Browse the latest order notes from your partners.
                              </p>
                            </div>
                          </div>
                          <div class="column is-one-fifth">
                            <div class="has-background-white-ter pt-xs rounded-lg has-text-weight-bold has-text-centered">
                              <span><b-icon icon="chevron-right" /></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </router-link>
                    <!-- Shipping & Delivery -->
                    <router-link :to="{ name: 'shipments.list', query: { preset: 'recent'} }">
                      <div class="mb-xs">
                        <div class="columns is-vcentered is-mobile">
                          <div class="column">
                            <div class="">
                              <h4 class="has-text-weight-bold">Shipments & Tracking</h4>
                              <p class="has-text-grey is-size-7">
                                Review shipments and tracking numbers.
                              </p>
                            </div>
                          </div>
                          <div class="column is-one-fifth">
                            <div class="has-background-white-ter pt-xs rounded-lg has-text-weight-bold has-text-centered">
                              <span><b-icon icon="chevron-right" /></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </router-link>
                    <!-- RMAs -->
                    <router-link :to="{ name: 'returns.list' }">
                      <div class="mb-xs">
                        <div class="columns is-vcentered is-mobile">
                          <div class="column">
                            <div class="">
                              <h4 class="has-text-weight-bold">Returns</h4>
                              <p class="has-text-grey is-size-7">
                                Track returns &amp; RMAs.
                              </p>
                            </div>
                          </div>
                          <div class="column is-one-fifth">
                            <div class="has-background-white-ter pt-xs rounded-lg has-text-weight-bold has-text-centered">
                              <span><b-icon icon="chevron-right" /></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </router-link>
                    <!-- Billing & Finance -->
                    <router-link :to="{ name: 'invoices.list', query: { preset: 'recent' } }">
                      <div class="mb-xs">
                        <div class="columns is-vcentered is-mobile">
                          <div class="column">
                            <div class="">
                              <h4 class="has-text-weight-bold">Invoices</h4>
                              <p class="has-text-grey is-size-7">
                                <span v-if="context.isMerchant()">Review invoices from suppliers.</span>
                                <span v-else>Review invoices sent to retailers</span>
                              </p>
                            </div>
                          </div>
                          <div class="column is-one-fifth">
                            <div class="has-background-white-ter pt-xs rounded-lg has-text-weight-bold has-text-centered">
                              <span><b-icon icon="chevron-right" /></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Advanced Search Modal -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showAdvancedSearchModal"
      :can-cancel="['x', 'esc']"
      ><AdvancedSearchModal />
    </b-modal>
    <!-- Import Shipments/Invoices Modal -->
    <b-modal :active.sync="showImportFulfillmentsModal" has-modal-card :can-cancel="[]">
      <ImportFulfillmentsModal :data-type="importDataType" />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import OrderAging from '../components/Dashboard/OrderAgingNew'
import OrderAttention from '../components/Dashboard/OrderAttention'
import OrderSearch from '../components/Dashboard/OrderSearch'
import ImportFulfillmentsModal from '../components/Dashboard/ImportFulfillmentsModal'
import AdvancedSearchModal from '../components/Dashboard/AdvancedSearchModal'

export default {
  components: {
    AdvancedSearchModal,
    ImportFulfillmentsModal,
    OrderAging,
    OrderAttention,
    OrderSearch
  },
  data () {
    return {
      importDataType: null,
      showAdvancedSearchModal: false,
      showImportFulfillmentsModal: false
    }
  },
  computed: {
    ...mapGetters(['app', 'auth', 'context'])
  },
  methods: {
    importFulfillemts (dataType) {
      this.importDataType = dataType
      this.showImportFulfillmentsModal = true
    }
  },
  mounted () {
    if (this.context.isMerchant() && !this.context.partner.isOnboarded) {
      this.$store.dispatch('pendingOnboardingTasks')
    }
  }
}
</script>
