<template>
  <InspectionModal
    :endpoint="endpoint"
    :loadDetail="loadDetail"
    :seed="selectedCredit">
    <template v-slot:default="props">
      <form>
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">Inspect Credit Memo</p>
          </header>
          <section class="modal-card-body">
            <!-- Loaded -->
            <div v-if="!props.loading && props.record !== null" class="">
              <p class="has-text-weight-bold mb-sm">Credit Memo Details</p>
              <table class="table is-fullwidth is-narrow is-striped is-size-7 border">
                <tbody>
                  <tr>
                    <th>ID</th>
                    <td>{{ props.record.id }}</td>
                  </tr>
                  <tr>
                    <th>Supplier Credit #</th>
                    <td>
                      <p v-if="props.record.credit_number">{{ props.record.credit_number }}</p>
                      <p v-else class="has-text-grey-light is-italic">None Provided</p>
                    </td>
                  </tr>
                  <tr>
                    <th>PO #</th>
                    <td>{{ props.record.order.purchase_order_number }}</td>
                  </tr>
                  <tr>
                    <th>Credit Amount</th>
                    <td class="has-text-weight-bold has-text-danger">
                      <CurrencyLocale :value="parseFloat(props.record.locale_credit_amount)" :currency="props.record.locale_currency"/>
                    </td>
                  </tr>
                  <tr>
                    <th>Merchant Status</th>
                    <td class="has-text-weight-bold">
                      <span v-if="props.record.acknowledged_at !== null" class="has-text-success">Received</span>
                      <span v-else>Sent</span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <!-- Lines -->
              <div class="mb-lg">
                <p class="has-text-weight-bold mb-sm">Credit Memo Line Items</p>
                <table class="table is-fullwidth is-striped is-vcentered is-size-7 is-narrow border">
                  <thead>
                    <tr>
                      <th>Item</th>
                      <th class="has-text-centered" style="width:96px">Qty</th>
                      <th class="has-text-centered" style="width:96px">Cost / Unit</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="line in props.record.credit_lines" :key="line.id">
                      <td>
                        <Identifiers :variant="line.variant" :showImage="false" nameInteraction="none" />
                      </td>
                      <td class="has-text-centered">{{ line.quantity }}</td>
                      <td class="has-text-centered"><CurrencyLocale :value="parseFloat(line.locale_net_price)" :currency="props.record.locale_currency"/></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- Adjustments -->
              <div class="mb-lg" v-if="props.record.adjustments.length > 0">
                <p class="has-text-weight-bold mb-sm">Adjustments</p>
                <table class="table is-striped is-fullwidth has-text-right border is-size-7 mb-lg">
                  <tbody>
                    <tr v-for="adjustment in props.record.adjustments" :key="adjustment.id">
                      <td>
                        <p>{{ adjustment.description }}</p>
                      </td>
                      <td class="has-text-right" style="vertical-align:middle">
                        <CurrencyLocale :value="parseFloat(adjustment.locale_net_price)" :currency="props.record.locale_currency"/>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- Totals -->
              <div class="mb-lg">
                <div class="columns">
                  <div class="column ">
                    <p class="has-text-centered is-uppercase has-text-primary is-size-7 has-text-weight-bold pb-sm">Totals</p>
                    <table class="table is-striped is-narrow is-size-7 is-fullwidth has-text-right border">
                      <tbody>
                        <tr>
                          <th>Subtotal</th>
                          <td class="has-text-right">
                            <CurrencyLocale :value="parseFloat(props.record.locale_subtotal)" :currency="props.record.locale_currency"/>
                          </td>
                        </tr>
                        <tr>
                          <th>Adjustments</th>
                          <td class="has-text-right">
                            <CurrencyLocale :value="parseFloat(props.record.locale_credit_amount - props.record.locale_subtotal)" :currency="props.record.locale_currency" :optionSignDisplay="'negative'"/>
                          </td>
                        </tr>
                        <tr>
                          <th>Due Amount</th>
                          <td class="has-text-right has-text-weight-bold">
                            <CurrencyLocale :value="parseFloat(props.record.locale_credit_amount)" :currency="props.record.locale_currency"/>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <!-- Loading -->
            <div v-else>
              <loading message="Loading credit memo..." />
            </div>
          </section>
          <footer class="modal-card-foot">
            <button class="button" type="button" @click="$parent.close()">Close</button>
          </footer>
        </div>
      </form>
    </template>
  </InspectionModal>
</template>

<script>
import Identifiers from '@/components/Models/Variant/Identifiers'
import InspectionModal from '../InspectionModal'
import { mapGetters } from 'vuex'

export default {
  components: {
    Identifiers,
    InspectionModal
  },
  data () {
    return {
      loadDetail: false
    }
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      if (this.selectedCredit !== null) {
        return this.context.route + '/credit-memos/' + this.selectedCredit.id + '/?short=1'
      }
      return null
    }
  },
  methods: {

  },
  mounted () {

  },
  props: ['selectedCredit']
}
</script>
