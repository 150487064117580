<template>
  <div>
    <div class="section">
      <div class="container">
        <!-- Page Header -->
        <div class="mb-md">
          <div class="columns is-vcentered">
            <div class="column is-one-third">
              <breadcrumbs :history="breadcrumbs.history" :current="breadcrumbs.current" />
            </div>
            <div class="column is-one-third">
              <h1 class="has-text-centered is-size-4">Item Detail</h1>
            </div>
          </div>
        </div>
        <!-- Item Loaded -->
        <div v-if="!loading && this.item !== null">
          <!-- Header -->
          <div class="has-background-white rounded-lg px-lg py-md mb-xxs">
            <div class="columns is-vcentered is-mobile">
              <div class="column">
                <p class="is-size-4">
                  <NamePresenter :variant="item" />
                </p>
                <p class="has-text-grey-light">
                  <SkuPresenter :variant="item" />
                </p>
              </div>
            </div>
          </div>
          <!-- Actions -->
          <div v-if="context.isSupplier()" class="has-background-white rounded-lg py-sm px-lg mb-xxs">
            <Workflow
              :item="item"
              @update:inventory="showUpdateInventoryModal = true"
              @update:sku="showUpdateSkuModal = true"
             />
          </div>
          <!-- Detail -->
          <div class="has-background-white pa-lg rounded-lg mb-lg">
            <ItemInfo :item="item" :hasShopifyAccount="hasShopifyAccount" @sync:item="showSyncItemModal = true"/>
          </div>
          <!-- Attibutes -->
          <ItemAttributes :item="item" />
        </div>
        <!-- Loading Skeleton... -->
        <div v-else>
          <div class="has-background-white pa-lg rounded-lg mb-xs">
            <div class="placeholder placeholder-title" style="width:66%"></div>
            <div class="placeholder placeholder-sentence" style="width:50%"></div>
          </div>
          <div class="has-background-white px-lg py-xxl rounded-lg mb-lg">
            <div class="columns">
              <div class="column">
                <div class="placeholder placeholder-sentence mb-lg"></div>
                <div class="placeholder placeholder-sentence mb-lg"></div>
                <div class="placeholder placeholder-sentence"></div>
              </div>
              <div class="column">
                <div class="placeholder placeholder-sentence mb-lg"></div>
                <div class="placeholder placeholder-sentence mb-lg"></div>
                <div class="placeholder placeholder-sentence"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Inventory Update Modal -->
    <b-modal
      v-if="context.isSupplier() && item !== null"
      has-modal-card
      :active.sync="showUpdateInventoryModal"
      :can-cancel="['escape', 'x']"
      ><UpdateInventoryModal :item="item" @inventory:updated="load(true)" />
    </b-modal>
    <!-- SKU Update Modal -->
    <b-modal
      v-if="context.isSupplier() && item !== null"
      has-modal-card
      :active.sync="showUpdateSkuModal"
      :can-cancel="['escape', 'x']"
      ><UpdateSkuModal :item="item" @item:updated="load(true)" />
    </b-modal>
    <!-- Sync Item Modal -->
    <b-modal
      v-if="context.isSupplier() && item !== null"
      has-modal-card
      :active.sync="showSyncItemModal"
      :can-cancel="['escape', 'x']"
      ><SyncItemModal :item="item" @item:updated="load(true)" :shopifyAccount="shopifyAccount" />
    </b-modal>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import NamePresenter from '@/components/Presenters/Variant/Name'
import SkuPresenter from '@/components/Presenters/Variant/Sku'
import Workflow from '../components/Item/Workflow'
import ItemInfo from '../components/Item/ItemInfo'
import ItemAttributes from '../components/Item/Attributes'
import UpdateInventoryModal from '@/components/Modals/Variant/UpdateInventoryModal'
import UpdateSkuModal from '@/components/Modals/Variant/UpdateSkuModal'
import SyncItemModal from '@/components/Modals/Variant/SyncItemModal'

export default {
  components: {
    ItemAttributes,
    ItemInfo,
    NamePresenter,
    SkuPresenter,
    Workflow,
    UpdateInventoryModal,
    UpdateSkuModal,
    SyncItemModal
  },
  data () {
    return {
      loading: true,
      refreshing: false,
      item: null,
      showUpdateInventoryModal: false,
      showUpdateSkuModal: false,
      showSyncItemModal: false,
      loadingPlatformAccounts: true,
      hasShopifyAccount: false,
      shopifyAccount: null
    }
  },
  computed: {
    ...mapGetters(['context']),
    breadcrumbs () {
      return {
        current: (this.loading) ? 'Loading...' : 'Item #' + this.$route.params.variantId,
        history: [
          { label: 'Catalog', name: this.context.isSupplier() ? 'catalog' : 'catalog.index' },
          { label: 'Items', name: 'catalog.items' }
        ]
      }
    },
    endpoint () {
      return this.context.route + '/variants/' + this.$route.params.variantId + '/'
    }
  },
  methods: {
    load (refresh = false) {
      if (!refresh) this.loading = true
      this.$http.get(this.endpoint).then(response => {
        this.item = response.data
        this.loading = false
      })
    },
    refresh () {
      this.refreshing = true
      this.$http.get(this.endpoint).then(response => {
        this.item = response.data
        this.refreshing = false
      })
    },
    loadShopifyPlatformAccount () {
      this.loadingPlatformAccounts = true
      const endpoint = this.context.route + '/platform-accounts/'
      const query = {
        params: {
          platform: 'shopify'
        }
      }
      this.$http.get(endpoint, query).then(response => {
        const accounts = response.data.results
        this.shopifyAccount = accounts && accounts.length > 0 && accounts[0]
        accounts.forEach(account => {
          if (account.platform.code === 'shopify') {
            this.hasShopifyAccount = true
          }
        })
        this.loadingPlatformAccounts = false
      })
    }
  },
  mounted () {
    this.load()
    this.loadShopifyPlatformAccount()
  },
  props: []
}
</script>
