<template>
  <form @submit.prevent="save" autocomplete="off">
    <!-- Intro -->
    <div class="has-text-centered">
      <p class="is-size-4 mb-sm has-text-black">Business Address</p>
      <p class="has-text-centered pb-lg">Please confirm your business address.</p>
    </div>
    <!-- Fields -->
    <div class="pb-lg mb-lg">
      <!-- Loaded -->
      <div v-if="!loading" class="columns is-centered">
        <div class="column is-two-thirds">
          <!-- Street Address -->
          <b-field
            label="Business Address"
            :type="('street1' in errors) ? 'is-danger' : ''"
            :message="('street1' in errors) ? errors['street1'][0] : ''"
            expanded>
              <b-input
                autocomplete="off"
                size="is-medium"
                type="text"
                v-model="location.address.street1"
              ></b-input>
          </b-field>
          <b-field
            :type="('street2' in errors) ? 'is-danger' : ''"
            :message="('street2' in errors) ? errors['street2'][0] : ''"
            expanded>
              <b-input
                autocomplete="off"
                size="is-medium"
                type="text"
                v-model="location.address.street2"
                placeholder="Suite, etc (optional)"
              ></b-input>
          </b-field>
          <!-- Country & City -->
          <div class="columns">
            <div class="column">
              <b-field
                label="Country"
                :type="('country' in errors) ? 'is-danger' : ''"
                :message="('country' in errors) ? errors['country'][0] : ''"
                  expanded>
                  <b-select v-model="location.address.country" name="country" placeholder="Select Country" size="is-medium" expanded>
                    <option value="US">United States</option>
                    <option value="CA">Canada</option>
                    <option value="GB">United Kingdom</option>
                    <option value="" disabled>--</option>
                    <option value="BE">Belgium</option>
                    <option value="CO">Colombia</option>
                    <option value="FR">France</option>
                    <option value="DE">Germany</option>
                    <option value="LB">Lebanon</option>
                    <option value="LT">Lithuania</option>
                    <option value="MQ">Martinique</option>
                    <option value="RO">Romania</option>
                    <option value="SC">Seychelles</option>
                    <option value="ES">Spain</option>
                    <option value="UA">Ukraine</option>
                  </b-select>
              </b-field>
            </div>
            <div class="column">
              <b-field
                label="City"
                :type="('city' in errors) ? 'is-danger' : ''"
                :message="('city' in errors) ? errors['city'][0] : ''"
                expanded>
                  <b-input
                    autocomplete="off"
                    size="is-medium"
                    type="text"
                    name="city"
                    v-model="location.address.city"
                  ></b-input>
              </b-field>
            </div>
          </div>
          <!-- Province & Postal Code -->
          <div class="columns">
            <div class="column">
              <b-field
                label="State / Province"
                :type="('province' in errors) ? 'is-danger' : ''"
                :message="('province' in errors) ? errors['province'][0] : ''"
                expanded>
                  <b-input
                    maxlength="2"
                    autocomplete="off"
                    size="is-medium"
                    type="text"
                    name="province"
                    v-model="location.address.province"
                  ></b-input>
              </b-field>
            </div>
            <div class="column">
              <b-field
                label="Postal Code"
                :type="('postal_code' in errors) ? 'is-danger' : ''"
                :message="('postal_code' in errors) ? errors['postal_code'][0] : ''"
                expanded>
                  <b-input
                    maxlength="12"
                    autocomplete="off"
                    size="is-medium"
                    type="text"
                    name="postalCode"
                    v-model="location.address.postal_code"
                  ></b-input>
              </b-field>
            </div>
          </div>
        </div>
      </div>
      <!-- Loading -->
      <div v-else class="py-lg">
        <loading message="Loading billing address..." />
      </div>
    </div>
    <!-- Buttons -->
    <div class="mb-xl">
      <div class="columns is-centered">
        <div class="column is-three-quarters-tablet is-two-thirds-widescreen">
          <div class="columns">
            <div class="column">
              <b-button type="is-light rounded-lg" size="is-large" @click="back" expanded>
                <b-icon icon="arrow-left"></b-icon>
              </b-button>
            </div>
            <div class="column">
              <b-button
                native-type="submit"
                type="is-primary rounded-lg"
                size="is-large"
                expanded
                :loading="saving"
                :disabled="!validated || saving"
              >Save & Continue</b-button>
            </div>
          </div>
        </div>
      </div>
      <p v-if="'detail' in errors" class="has-text-danger">{{ error.detail }}</p>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  data () {
    return {
      loading: true,
      saving: false,
      errors: {},
      location: {}
    }
  },
  computed: {
    ...mapGetters(['context']),
    endpoint () {
      return ('id' in this.location)
        ? this.context.route + '/locations/' + this.location.id + '/'
        : this.context.route + '/locations/'
    },
    method () {
      return ('id' in this.location) ? 'patch' : 'post'
    },
    json () {
      return {
        nickname: 'Billing Address',
        type: 'billing',
        address: {
          name1: this.context.partner.name,
          street1: this.location.address.street1,
          street2: this.location.address.street2,
          country: this.location.address.country,
          city: this.location.address.city,
          province: this.location.address.province,
          postal_code: this.location.address.postal_code,
          phone1: (this.location.address.phone1 !== null && this.location.address.phone1 !== '') ? this.location.address.phone1 : null
        }
      }
    },
    validated () {
      return true
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get(this.endpoint + '?type=billing').then(response => {
        // if we have a billing address already on file, use it
        if (response.data.results.length > 0) {
          this.location = response.data.results[0]
        // otherwise, use the shopify data to backfill
        } else {
          this.location = {
            nickname: 'Billing Address',
            type: 'billing',
            address: {
              name1: this.shop.name,
              street1: this.shop.address1,
              street2: this.shop.address2,
              country: this.shop.country_code,
              city: this.shop.city,
              province: this.shop.province_code,
              postal_code: this.shop.zip,
              phone1: (this.shop.phone !== null && this.shop.phone !== '') ? this.shop.phone : null
            }
          }
        }
        this.loading = false
      })
    },
    back () {
      this.$emit('change:step', 'user')
    },
    save () {
      this.saving = true
      this.$http[this.method](this.endpoint, this.json).then(response => {
        this.$emit('complete')
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {
    this.load()
  },
  props: ['shop']
}
</script>
