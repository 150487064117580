<template>
  <div>
    <!-- Content -->
    <div class="">
      <p class="mb-lg">
        The {{ app.productName }} Shopify app can be installed to automatically push purchase
        orders into your Shopify store and pull tracking numbers once you ship. Please visit the
        <a href="https://apps.shopify.com/app85151" class="has-text-info has-text-weight-bold">Shopify App Store</a>,
        search for 'fabric Dropship Platform', and follow the instructions to install and authorize the app.
      </p>
      <p class="mb-lg">
        If you need any assistance, please contact
        <a :href="'mailto:' + app.supportEmail">{{ app.supportEmail}}</a>.
      </p>
      <!-- Account Exists -->
      <div v-if="account !== null">
        <ShopifyPresenter :account="account" />
      </div>
      <!-- Account Doesn't Exist -->
      <div v-else class="mb-lg">
        <div v-if="'detail' in errors" class="mb-sm">
          <b-message type="is-danger" >{{ errors.detail}}</b-message>
        </div>
      </div>
    </div>
    <!-- Navigate -->
    <div class="py-lg">
      <div class="columns is-centered">
        <div class="column">
          <b-button
            type="is-light rounded-lg"
            size="is-medium"
            @click="goBack"
            expanded
            ><b-icon icon="arrow-left"></b-icon>
          </b-button>
        </div>
        <div class="column">
          <b-button
            type="is-primary rounded-lg"
            size="is-medium"
            expanded
            @click="goForward"
            :disabled="saving || !ready"
            :loading="saving"
          >Continue</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ShopifyPresenter from '@/components/Models/PlatformAccount/Presenters/Shopify'

export default {
  components: {
    ShopifyPresenter
  },
  computed: {
    ...mapGetters(['app', 'context']),
    ready () {
      return (this.account || (this.storeName))
    }
  },
  data () {
    return {
      storeName: null,
      storeToken: null,
      saving: false,
      errors: {}
    }
  },
  methods: {
    goBack () {
      this.$router.push({ name: 'onboarding.tasks', params: { connection: this.connection.id } })
    },
    goForward () {
      this.errors = {}
      // if platform account exists, don't do anything
      if (this.account !== null) {
        this.$emit('navigate', 'Install')
        return
      }
      this.saving = true
      const endpoint = this.context.route + '/shopify/check-shop-availability/'
      const json = {
        shop_name: this.storeName
      }
      this.$http.put(endpoint, json).then(response => {
        // if response status is 200 create platform account
        if (response.status === 200) {
          this.createPlatformAccount()
        }
      }).catch((error) => {
        if (error.response.status === 400 && error.response.data) {
          this.errors = {
            detail: 'That store name is already associated with another account in our system. Please check your store name or contact support@fabric.inc for assistance.'
          }
        }
        this.saving = false
      })
    },
    createPlatformAccount () {
      const endpoint = this.context.route + '/platform-accounts/'
      const json = {
        platform: { code: 'shopify' },
        credentials: [{
          credential: { code: 'shopify_shop_name' },
          value: this.storeName
        }, {
          credential: { code: 'shopify_private_app' },
          value: 1
        }
        // Disabling shopify_token for time being
        // {
        //   credential: { code: 'shopify_token' },
        //   value: this.storeToken
        // }
        ]
      }
      this.$http.post(endpoint, json).then(response => {
        this.$buefy.toast.open({ type: 'is-info', message: 'Platform Added!' })
        this.$emit('platform:created', response.data)
        this.$emit('navigate', 'Install')
      }).catch(() => {
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  props: {
    connection: {
      type: Object,
      required: true
    },
    task: {
      type: Object,
      required: true
    },
    account: {
      type: Object
    }
  }
}
</script>
