import Vue from 'vue'
import axios from 'axios'
import router from '@/router'

const state = {
  authenticating: false,
  identifying: true,
  token: null,
  user: null,
  localContext: null,
  context: null,
  suppliers: [],
  retailers: [],
  productMode: null,
  ready: false,
  intended: null,
  pendingOnboardingTasks: null
}

const getters = {
  auth (state, getters, rootState) {
    // determine whether to shroud/cover app b/c auth markers are missing
    let shroud = true
    if (state.token !== null && state.user !== null) shroud = false
    if ('skipAuth' in rootState.route.meta && rootState.route.meta.skipAuth) shroud = false

    // determine if user is rc staff user
    const staff = (state.user !== null && state.user.isRevcascade)

    // determine if user has more than a single context
    const hasContexts = (
      state.suppliers.length > 1 ||
      state.retailers.length > 1 ||
      (state.suppliers.length && state.retailers.length) ||
      staff
    )

    // return auth state
    return {
      authenticating: state.authenticating,
      identifying: state.identifying,
      intended: state.intended,
      ready: state.ready,
      token: state.token,
      user: state.user,
      suppliers: state.suppliers,
      retailers: state.retailers,
      productMode: state.productMode,
      hasContexts: hasContexts,
      isStaff: staff,
      isGuest: (state.user === null),
      shroud: shroud,
      pendingOnboardingTasks: state.pendingOnboardingTasks
    }
  },
  context (state) {
    return {
      ...state.context,
      isResolved () {
        return state.context !== null
      },
      isUnresolved () {
        return state.context === null
      },
      isSupplier () {
        return state.context !== null && state.context.type === 'vendor'
      },
      isMerchant () {
        return state.context !== null && state.context.type === 'retailer'
      }
    }
  }
}

const mutations = {
  signIn: state => {
    state.authenticating = true
  },
  signOut (state) {
    state.user = null
    state.token = null
    state.context = null
    state.localContext = null
    state.intended = null
  },
  redirectGuest (state, intended) {
    if (intended !== null && typeof intended !== 'undefined') {
      state.intended = intended
    }
    state.context = null
    state.localContext = null
    state.user = null
    state.token = null
  },
  setToken: (state, token) => {
    state.token = token
    axios.defaults.headers.common.Authorization = 'Bearer ' + token
  },
  setUser (state, payload) {
    // simplify user object
    state.user = {
      id: payload.id,
      email: payload.email,
      firstName: payload.first_name,
      lastName: payload.last_name,
      fullName: payload.first_name + ' ' + payload.last_name,
      initials: payload.first_name.charAt(0) + payload.last_name.charAt(0),
      title: payload.title,
      isRevcascade: (payload.is_revcascade),
      requirePasswordChange: false,
      preferences: {},
      permissions: []
    }
    // the 'require_password_change' key is only present on the user object
    // if the user is required to change their password.
    if ('require_password_change' in payload) {
      state.user.requirePasswordChange = payload.require_password_change
    }
    // clean up and set user preferences
    payload.preferences.forEach((preference) => {
      state.user.preferences[preference.code] = preference.user_preference
    })
    // To-Do: set only set of permissions and not all
    payload.permissions.forEach(permission => {
      state.user.permissions.push({
        name: permission.name,
        contentType: permission.content_type
      })
    })
    // set available contexts
    state.suppliers = ('brands' in payload) ? payload.brands : []
    state.retailers = ('retailers' in payload) ? payload.retailers : []
  },
  setUserPreferences (state, payload) {
    if (payload.preferences) {
      payload.preferences.forEach((preference) => {
        state.user.preferences[preference.code] = preference.user_preference
      })
    }
  },
  setContext (state, payload) {
    localStorage.setItem('rcc', JSON.stringify({ type: payload.type, id: payload.partner.id }))
    if (!payload.retainSessionStorage) {
      sessionStorage.clear()
    }
    state.ready = false
    state.productMode = null
    state.context = {
      type: payload.type,
      label: (payload.type === 'vendor') ? 'Supplier' : 'Merchant',
      partner: {
        id: payload.partner.id,
        name: payload.partner.name,
        code: payload.partner.code,
        website: payload.partner.website,
        isRcn: (payload.type === 'vendor') ? payload.partner.is_on_rcn : payload.partner.is_rcn_retailer,
        isOnboarded: payload.partner.is_onboarded,
        requiresSubscription: payload.partner.requires_subscription,
        isExpired: payload.type === 'vendor' && payload.partner.subscription_is_expired,
        isOnGracePeriod: payload.type === 'vendor' && payload.partner.subscription_is_on_grace_period,
        isDelinquent: payload.type === 'vendor' && payload.partner.subscription_is_delinquent,
        subscriptionExpiresAt: payload.type === 'vendor' ? payload.partner.subscription_expires_at : null,
        gracePeriodEndsAt: payload.type === 'vendor' ? payload.partner.grace_period_ends_at : null
      },
      route: (payload.type === 'vendor') ? 'brands/' + payload.partner.id : 'retailers/' + payload.partner.id
    }
    if (payload.type === 'retailer') {
      state.context.partner.index_template_id = payload.partner.indexing_template_id
    }
  },
  setLocalContext (state, payload) {
    if (payload.type !== 'vendor' && payload.type !== 'retailer') return
    if (isNaN(parseInt(payload.id))) return
    state.localContext = {
      ...payload,
      route: (payload.type === 'vendor') ? 'brands/' + payload.id + '/' : 'retailers/' + payload.id + '/'
    }
  },
  setProductMode (state, mode) {
    state.productMode = (mode === 'transactions_and_products')
      ? 'transactions_and_products'
      : 'products_only'
    state.intended = null
    state.ready = true
    state.identifying = false
  },
  updateUserInfo (state, payload) {
    if ('firstName' in payload) state.user.firstName = payload.firstName
    if ('lastName' in payload) state.user.lastName = payload.lastName
    if ('email' in payload) state.user.email = payload.email
  },
  updateContextInfo (state, payload) {
    if ('name' in payload) state.context.partner.name = payload.name
    if ('website' in payload) state.context.partner.website = payload.website
  },
  changedPassword (state) {
    state.user.requirePasswordChange = false
  },
  ready (state) {
    state.ready = true
    state.identifying = false
  },
  pendingOnboardingTasks (state, payload) {
    state.pendingOnboardingTasks = payload.count
  }
}

const actions = {
  /**
   * Trigger state to be configured such that the user is redirected to or
   * shown a login prompt (and keep track of where the user was intending
   * to go so we can redirect after authentication)
   */
  redirectGuest: ({ commit, state }, intendedPath) => {
    sessionStorage.clear()
    localStorage.clear()
    commit('redirectGuest', intendedPath)
    router.push({ name: 'auth.login' })
  },
  /**
   * Store the access token in the vue session & localstorage (for when
   * users refresh). Then make authenticated call to identify the user. The
   * payload is a full access token response.
   */
  authenticate: ({ commit, dispatch, getters }, payload) => {
    return new Promise((resolve) => {
      localStorage.setItem('rct', payload.access_token)
      commit('setToken', payload.access_token)
      dispatch('identify').then(() => {
        resolve()
      })
    })
  },
  /**
   * Update the user's profile data (which is useful after updating
   * the the user's profile settings)
   */
  refreshUser ({ commit }, user) {
    commit('setUser', user)
  },
  /**
   * Update the user's profile data (which is useful after updating
   * the the user's preference settings)
   */
  refreshUserPreferences ({ commit }, user) {
    commit('setUserPreferences', user)
  },
  /**
   * After the user has completed a force password change, reset the flag
   * so that navigation guards allow user to pass.
   */
  changedPassword ({ commit }) {
    commit('changedPassword')
  },
  /**
   * Identify user by check if the user has a valid session by calling the '/me' endpoint
   * If the request fails, we will assume the user is not authenticated,
   * so we'll redirect them to the login page.
   */
  identify ({ commit, state, dispatch }) {
    // if we don't have a token, don't even bother
    if (state.token === null) {
      commit('ready')
      return
    }
    // otherwise, attempt to indentify the user
    return new Promise((resolve, reject) => {
      state.identifying = true
      axios.get('users/me/').then(response => {
        commit('setUser', response.data)
        // resolve non-staff, single suppliers
        if (!response.data.is_revcascade && state.suppliers.length === 1 && state.retailers.length === 0) {
          dispatch('setContext', { type: 'vendor', partner: state.suppliers[0] })
          resolve()
        // resolve non-staff, single retailers
        } else if (!response.data.is_revcascade && state.suppliers.length === 0 && state.retailers.length === 1) {
          dispatch('setContext', { type: 'retailer', partner: state.retailers[0] })
          resolve()
        // resolve using verified local cache
        } else if (state.localContext !== null) {
          axios.get(state.localContext.route + '?short=1').then(response => {
            dispatch('setContext', { type: state.localContext.type, partner: response.data, retainSessionStorage: true })
            resolve()
          }).catch(error => {
            localStorage.removeItem('rcc')
            reject(error)
          })
        } else {
          commit('ready')
          resolve()
        }
      }).catch(error => {
        sessionStorage.clear()
        localStorage.clear()
        commit('signOut')
        commit('ready')
        reject(error)
      })
    })
  },
  /**
   * Log a user out out the application by posting a request to the api
   * followed by destroying user information from the local state.
   */
  logout ({ commit }, redirectToSignIn = true) {
    sessionStorage.clear()
    localStorage.clear()
    commit('signOut')
    Vue.prototype.$buefy.toast.open({ type: 'is-danger', message: 'You have been signed out!' })
    if (redirectToSignIn) {
      router.push({ name: 'home' })
    }
  },
  /**
   * Validate that an access token from localStorage is roughly what we
   * expect. If so, commit to vuex for future server side validation
   */
  setToken ({ commit, state, rootState }, payload) {
    commit('token', payload)
  },

  setContext ({ commit, dispatch }, payload) {
    commit('setContext', payload)
    dispatch('setProductMode')
  },

  setProductMode ({ commit, state }, payload) {
    const endpoint = state.context.route + '/connections/'
    const query = {
      params: {
        pagination: 0,
        mini: 1,
        status: 'active,onboarding'
      }
    }
    axios.get(endpoint, query).then(response => {
      const connections = response.data.results.length
      for (let i = 0; i < connections; i++) {
        if (response.data.results[i].level === 'full' || response.data.results[i].level === 'transactions_and_products') {
          commit('setProductMode', 'transactions_and_products')
          break
        }
      }
      if (!state.productMode) {
        commit('setProductMode', 'products_only')
      }
    })
  },

  updateUserInfo ({ commit }, payload) {
    commit('updateUserInfo', payload)
  },

  updateContextInfo ({ commit }, payload) {
    commit('updateContextInfo', payload)
  },

  pendingOnboardingTasks ({ commit, state, dispatch }) {
    if (state.context.partner.isOnboarded) {
      commit('pendingOnboardingTasks', { count: 0, loading: false })
      return
    }
    // otherwise make an api call to fetch assigned tasks
    return new Promise((resolve, reject) => {
      const path = `retailers/${state.context.partner.id}/assigned-tasks/`
      axios.get(path).then(response => {
        const tasks = response.data.results.filter(el => el.completed_at === null)
        commit('pendingOnboardingTasks', { count: tasks.length, loading: false })
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  }

}

export default {
  state, getters, mutations, actions
}
