<template>
  <div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Export Proposal</p>
      </header>
      <section class="modal-card-body">
        <div v-if="!loadingPlatformAccounts">
          <!-- Export Method -->
          <div class="mb-lg">
            <p class="mb-sm"><label class="label">Method</label></p>
            <div class=""><b-radio v-model="exportMethod" native-value="file">Export proposal to a template file</b-radio></div>
            <div v-if="proposal.status === 'completed'">
              <b-radio v-model="exportMethod" native-value="platform" :disabled="platformAccounts.length === 0">
                Export proposal to
                <span v-if="platformAccount">{{ platformAccount.platform.name }}</span>
                <span v-else>a connected platform</span>
              </b-radio>
            </div>
          </div>
          <!-- File Templates -->
          <div v-if="exportMethod === 'file'">
            <b-field label="Template">
              <b-select
                v-model="fileExportTemplate"
                size="is-medium"
                placeholder="Select an export template..."
                :loading="loadingFileExportTemplates"
                :disabled="loadingFileExportTemplates"
                expanded>
                <option v-for="template in fileExportTemplates" :key="template.id" :value="template">{{ template.name }}</option>
              </b-select>
            </b-field>
            <!-- No Templates Available -->
            <div v-if="!loadingFileExportTemplates && fileExportTemplates.length === 0">
              <p class="has-text-centered has-text-grey-light">
                Sorry, but you do not have any eligible export templates. Please file a
                <a href="#" @click.prevent="$store.dispatch('supportRequest')" class="has-text-info">support request</a>
                for further assistance.
              </p>
            </div>
          </div>
          <!-- Platform Export Templates -->
          <div v-if="exportMethod === 'platform'">
            <b-field label="Template">
              <b-select
                v-model="platformExportTemplate"
                size="is-medium"
                placeholder="Select an export template..."
                :loading="loadingPlatformExportTemplates"
                :disabled="loadingPlatformExportTemplates"
                expanded>
                <option v-for="template in platformExportTemplates" :key="template.id" :value="template">{{ template.name }}</option>
              </b-select>
            </b-field>
          </div>
        </div>
        <!-- Loading -->
        <div v-else>
          <loading message="Loading templates..." />
        </div>
      </section>
      <footer class="modal-card-foot space-between" >
        <b-button @click="$parent.close()">Close</b-button>
        <b-button
          type="is-primary"
          :disabled="!ready || saving"
          :loading="saving"
          @click="exportProposal">Export</b-button>
      </footer>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['app', 'context']),
    ready () {
      if (this.exportMethod === 'file' && this.fileExportTemplate) return true
      if (this.exportMethod === 'platform' && this.platformAccount && this.platformExportTemplate) return true
      return false
    },
    loading () {
      return Boolean(
        this.loadingPlatformAccounts ||
        this.loadingPlatformExportTemplates ||
        this.loadingFileExportTemplates
      )
    }
  },
  data () {
    return {
      exportMethod: null,
      loadingPlatformAccounts: true,
      loadingPlatformExportTemplates: false,
      loadingFileExportTemplates: false,
      platformAccount: null,
      platformExportTemplate: null,
      fileExportTemplate: null,
      platformAccounts: [],
      platformExportTemplates: [],
      fileExportTemplates: [],
      saving: false
    }
  },
  methods: {
    loadPlatformAccounts () {
      this.loadingPlatformAccounts = true
      const endpoint = this.context.route + '/platform-accounts/?platform=shopify,bigcommerce'
      this.$http.get(endpoint).then(response => {
        if (response.data.count > 0) {
          this.platformAccounts = response.data.results
          if (this.platformAccounts.length === 1) {
            this.platformAccount = response.data.results[0]
          }
        }
        this.loadingPlatformAccounts = false
      })
    },
    loadFileExportTemplates () {
      this.fileExportTemplates = []
      this.loadingFileExportTemplates = true
      const endpoint = this.context.route + '/templates/'
      const query = {
        params: { data_type: 'products', direction: 'export' }
      }
      this.$http.get(endpoint, query).then(response => {
        response.data.results.forEach(result => {
          if (result.code !== '' && result.retailer !== null) {
            this.fileExportTemplates.push(result)
          }
        })
        if (this.fileExportTemplates.length === 1) {
          this.fileExportTemplate = this.fileExportTemplates[0]
        }
        this.loadingFileExportTemplates = false
      })
    },
    loadPlatformExportTemplates () {
      this.platformExportTemplates = []
      this.loadingPlatformExportTemplates = true
      const endpoint = this.context.route + '/' + this.platformAccount.platform.code + '/' + this.platformAccount.id + '/templates/'
      const query = {
        params: { direction: 'export' }
      }
      this.$http.get(endpoint, query).then(response => {
        response.data.results.forEach(result => {
          if (result.code !== '') {
            this.platformExportTemplates.push(result)
          }
        })
        if (this.platformExportTemplates.length === 1) {
          this.platformExportTemplate = this.platformExportTemplates[0]
        }
        this.loadingPlatformExportTemplates = false
      })
    },
    exportProposal () {
      this.saving = true
      if (this.exportMethod === 'file' && this.fileExportTemplate) {
        this.exportToFile()
      } else if (this.exportMethod === 'platform' && this.platformAccount && this.platformExportTemplate) {
        this.exportToPlatform()
      }
    },
    exportToFile () {
      this.saving = true
      const endpoint = this.context.route + '/proposals/' + this.proposal.id + '/export/'
      const query = {
        params: { template_id: this.fileExportTemplate.id }
      }
      this.$http.get(endpoint, query).then(response => {
        this.$emit('proposal:exported')
        this.$buefy.toast.open({ type: 'is-success', message: 'Proposal export scheduled! Please allow 5-10 minutes for delivery.' })
        this.$parent.close()
      }).finally(() => {
        this.saving = false
      })
    },
    exportToPlatform () {
      this.saving = true
      const endpoint = this.context.route + '/' + this.platformAccount.platform.code + '/' + this.platformAccount.id + '/push-products/'
      const json = {
        proposal_id: this.proposal.id
      }
      if (this.platformAccount.platform.code === 'shopify') {
        json.shopify_template_id = this.platformExportTemplate.id
      } else if (this.platformAccount.platform.code === 'bigcommerce') {
        json.bigcommerce_template_id = this.platformExportTemplate.id
      }
      this.$http.post(endpoint, json).then(response => {
        this.$emit('proposal:published')
        this.$buefy.toast.open({ type: 'is-success', message: 'Publish attempt scheduled! Please allow 5-10 minutes for delivery.' })
        this.$parent.close()
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {
    this.exportMethod = 'file'
    this.loadPlatformAccounts()
  },
  watch: {
    exportMethod () {
      if (this.exportMethod === 'file') this.loadFileExportTemplates()
      if (this.exportMethod === 'platform') this.loadPlatformExportTemplates()
    }
  },
  props: {
    proposal: {
      type: Object,
      required: true
    }
  }
}
</script>
