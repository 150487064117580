<template>
  <form @submit.prevent="update" novalidate>
    <div class="columns">
      <div class="column is-one-fifth">
        <p class="has-text-weight-bold is-size-6 mb-sm">Security Credentials</p>
        <p class="has-text-grey is-size-7">
          Update the password for this {{ app.platform }} account.
          <br><br>
          When you reset the password for this user, they will
          be required to change their password once they login successfully.
        </p>
      </div>
      <div class="column">
        <div class="has-background-white rounded-xl">
          <!-- Loaded -->
          <div v-if="!loading && user !== null" class="pa-lg pb-xl">
            <div class="columns">
              <div class="column is-two-thirds-tablet is-half-desktop">
                <b-field
                  label="New Password"
                  expanded>
                  <password
                    autocomplete="new-password"
                    v-model="newPassword"
                    defaultClass="input"
                    placeholder=""
                    :toggle="false"
                    :badge="false"
                    @score="setScore"
                  ></password>
                </b-field>
                <b-field
                  label="Confirm Password"
                  expanded>
                    <b-input
                      autocomplete="new-password"
                      v-model="confirmPassword"
                      type="password"
                      name="confirmPassword"
                      @input="isDirty = 1"
                    ></b-input>
                </b-field>
              </div>
            </div>
          </div>
          <!-- Loading -->
          <div v-else>
            <loading message="Loading user..." />
          </div>
          <div class="border-t">
            <div class="columns is-vcentered is-marginless">
              <div class="column">
                <div class="px-sm has-text-grey-light">
                  <i v-if="error !== null" v-text="error" class="has-text-danger"></i>
                  <i v-else v-text="validationMessage.body" :class="validationMessage.class"></i>
                </div>
              </div>
              <div class="column is-narrow">
                <div class="px-sm">
                  <b-button native-type="submit" type="is-primary rounded-lg" :disabled="updating || !ready">Update</b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import Password from 'vue-password-strength-meter'

export default {
  components: {
    Password
  },
  computed: {
    ...mapGetters(['app', 'api', 'auth', 'context']),
    endpoint () {
      return this.context.route + '/users/' + this.user.id + '/update-user-password/'
    },
    pass () {
      return Math.random().toString(36).substring(2, 15)
    },
    json () {
      return {
        password: this.newPassword
      }
    },
    ready () {
      return (!this.updating &&
        this.isDirty &&
        this.newPassword !== null &&
        this.newPassword !== '' &&
        this.newPassword === this.confirmPassword &&
        this.passwordScore >= 2
      )
    },
    validationMessage () {
      const message = {
        class: 'has-text-grey',
        body: ''
      }
      if (this.isDirty) {
        if (this.newPassword === null || this.newPassword === '') {
          message.body = 'Please enter a new password.'
        } else if (this.newPassword !== this.confirmPassword) {
          message.body = 'Please make sure to confirm your new password.'
        } else if (this.passwordScore < 2) {
          message.body = 'Please strengthen your password until you see a green score.'
        }
      }
      return message
    }
  },
  data () {
    return {
      isDirty: false,
      password: null,
      newPassword: null,
      confirmPassword: null,
      passwordScore: 0,
      lastName: null,
      error: null,
      updating: false
    }
  },
  methods: {
    setScore (score) {
      this.isDirty = true
      this.passwordScore = score
    },
    update () {
      this.updating = true
      this.$http.patch(this.endpoint, this.json).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Password updated.' })
        this.reset()
        this.updating = false
      }).catch(error => {
        this.updating = false
        if (error.response && error.response.status === 400) {
          if (error.response.data.length > 0) {
            this.error = error.response.data[0]
          } else {
            this.error = 'An unknown error occurred. Please contact support!'
          }
        }
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      })
    },
    reset () {
      this.newPassword = ''
      this.confirmPassword = ''
      this.error = null
    }
  },
  props: {
    loading: {
      required: true,
      type: Boolean
    },
    user: {
      required: true,
      validator: prop => typeof prop === 'object' || prop === null
    }
  }
}
</script>
