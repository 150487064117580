<template>
  <div class="section">
    <div class="container">
      <!-- Page Header -->
      <div class="columns is-vcentered">
        <div class="column is-one-third">
          <breadcrumbs :history="[{ label: 'Order' }]" current="Utilities" :hideBack="true" />
        </div>
        <div class="column is-one-third">
          <h1 class="has-text-centered is-size-4">Order Utilities</h1>
        </div>
      </div>
      <!-- Step 1: Select action -->
      <div class="mb-lg pb-lg has-border-b">
        <div class="columns">
          <div class="column is-one-fifth">
            <p class="has-text-weight-bold is-size-6 mb-sm">Step 1: Select your action</p>
            <p class="has-text-grey">Action you want to perform on your items.</p>
          </div>
          <div class="column">
            <div class="has-background-white rounded-xl pa-xl">
              <div>
                <b-field label="Action">
                  <b-select v-model="selectedAction" placeholder="Select Action" required expanded>
                    <option value="cancel_orders_po_number">Cancel Entire Orders by PO Number</option>
                    <option value="cancel__order_lines_by_sku">Cancel specific order lines by SKU</option>
                    <option value="cancel_order_lines_specific_sku">Cancel all order lines of specific SKU</option>
                  </b-select>
                </b-field>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Step 2: Download Template -->
      <div v-if="selectedAction" class="mb-lg pb-lg has-border-b">
        <div class="columns">
          <div class="column is-one-fifth">
            <p class="has-text-weight-bold is-size-6 mb-sm">Step 2: Download Template</p>
            <p class="has-text-grey">Download a template to delete the desired Orders.</p>
          </div>
          <div class="column">
            <div class="has-background-white rounded-xl pa-xl">
              <!-- Instructions -->
              <div class="mb-lg">
                <div class="columns is-vcentered">
                  <div class="column">
                    <p v-if="selectedAction === 'cancel_orders_po_number'">
                      {{ getCase1Description }}
                    </p>
                    <p v-if="selectedAction === 'cancel__order_lines_by_sku'">
                      {{ getCase2Description }}
                    </p>
                    <p v-if="selectedAction === 'cancel_order_lines_specific_sku'">
                      {{ getCase3Description }}
                    </p>
                  </div>
                  <div class="column is-narrow">
                    <b-button type="is-primary is-fullwidth is-outlined rounded-lg" @click="downloadTemplate">Download
                      Template</b-button>
                  </div>
                </div>
              </div>
              <div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Step 3: Upload File -->
      <div v-if="selectedAction" class="mb-lg pb-lg has-border-b">
        <div class="columns">
          <div class="column is-one-fifth">
            <p class="has-text-weight-bold is-size-6 mb-sm">Step 3: Upload File</p>
            <p class="has-text-grey">Please upload your file here.</p>
          </div>
          <div class="column">
            <div class="has-background-white rounded-xl">
              <div class="pa-xl">
                <!-- Upload -->
                <CoreUpload @file:uploaded="updateTempfile" @file:reset="resetTempfile" />
              </div>
              <div class="has-border-t">
                <div class="columns is-vcentered is-marginless">
                  <div class="column">
                    <div class="px-sm has-text-grey-light">
                      <i v-if="tempfile !== null">Click 'Import File' to upload your file for processing.</i>
                    </div>
                  </div>
                  <div class="column is-narrow">
                    <form @submit.prevent="queueFile">
                      <div class="px-sm">
                        <b-button native-type="submit" type="is-primary" :loading="scheduling"
                          :disabled="!ready || scheduling">Import File</b-button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="job !== null" class="mb-lg pb-lg has-border-b">
        <div class="columns">
          <div class="column is-one-fifth">
            <p class="has-text-weight-bold is-size-6 mb-sm">Job Status</p>
            <p class="has-text-grey"></p>
          </div>
          <div class="column">
            <div class="has-background-white rounded-xl pa-xl">
              <div class="mb-lg">
                <b-message v-if="job.finish_time !== null && job.error_logs.length === 0" type="is-success">
                  Your cancel request is complete with 0 errors. Nice job!
                </b-message>
                <b-message v-else-if="job.finish_time !== null && job.error_logs.length > 0" type="is-warning">
                  Your cancel request is complete, but we detected some errors. Please
                  check the error log to review the errors. For any rows not
                  listed in the error log, you may assume orders were canceled correctly.
                </b-message>
                <b-message v-else type="is-warning">
                  Your cancel request is still processing.
                  Click <a href="#" @click.prevent="loadJob">here</a> to refresh
                  the job status.
                </b-message>
                <p class="has-text-weight-bold mb-sm">Job ID #{{ job.id }}</p>
                <div class="columns">
                  <div class="column">
                    <div class="columns is-mobile is-vcentered">
                      <div class="column is-narrow">
                        <p class="has-text-weight-bold">Started</p>
                        <p class="has-text-weight-bold">Finished</p>
                      </div>
                      <div class="column">
                        <!-- Start Time -->
                        <p v-if="job.started_at === null" class="has-text-grey">Queued</p>
                        <p v-else>{{ $dates.utc(job.started_at).local().format('MMM D, YYYY @ h:mm A') }}</p>
                        <!-- End Time -->
                        <p v-if="job.finish_time === null">
                          <span> -- </span>
                        </p>
                        <p v-else>{{ $dates.utc(job.finish_time).local().format('MMM D, YYYY @ h:mm A') }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="column is-narrow">
                    <div class="columns is-mobile is-vcentered">
                      <div class="column is-narrow">
                        <p class="has-text-success has-text-weight-bold"># Success</p>
                        <p class="has-text-danger has-text-weight-bold"># Failed</p>
                      </div>
                      <div class="column">
                        <p>
                          <span v-if="job.total_success !== null">{{ job.total_success }}</span>
                          <span v-else>--</span>
                        </p>
                        <p>
                          <span v-if="job.total_failure !== null">{{ job.total_failure }}</span>
                          <span v-else>--</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="job.error_logs.length > 0" class="mb-lg">
                  <p class="has-text-weight-bold mb-sm">Errors ({{ job.error_logs.length }})</p>
                  <table class="table is-fullwidth is-striped is-size-7 is-narrow border">
                    <thead>
                      <tr>
                        <th>Message</th>
                        <th class="has-text-centered" style="width:96px">Count</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="error in job.error_logs" :key="error.id">
                        <td>{{ error.message }}</td>
                        <td class="has-text-centered">{{ error.count }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CoreUpload from '@/components/CoreUpload'
export default {
  components: {
    CoreUpload
  },
  computed: {
    ...mapGetters(['app', 'assets', 'context']),
    ready () {
      return this.tempfile !== null
    },
    endpoint () {
      return this.context.route + '/cancels/bulk-with-file/'
    },
    getCase1Description () {
      return 'This operation would cancel all orders of the given Purchase Order Numbers. All 3 fields are mandatory. If anyone are missing, that line will be skipped'
    },
    getCase2Description () {
      return 'This operation would cancel all orders lines given purchase order of the given SKU. Only the specific SKUs and their specified quantities would be cancelled from each of the orders for which a PO number is provided'
    },
    getCase3Description () {
      return 'This operation would cancel all orders lines of the given SKU. All orders containing the affected SKU(s) will have those SKUs cancelled.'
    },
    jobErrors () {
      const errors = []
      if (this.job !== null && this.job.error_logs.length > 0) {
        this.job.error_logs.forEach(log => {
          errors.push(log.message)
        })
      }
      return errors.join('\r')
    }
  },
  data () {
    return {
      tempfile: null,
      scheduling: false,
      selectedAction: null,
      job_id: null,
      loading: false,
      job: null
    }
  },
  methods: {
    queueFile () {
      this.scheduling = true
      this.$http.post(this.endpoint, {
        tempfile_id: this.tempfile.id
      }).then(response => {
        this.scheduling = false
        this.$buefy.toast.open({ type: 'is-success', message: 'File scheduled!' })
        if (response.data) {
          this.job_id = response.data.id
          this.loadJob()
        }
      })
    },
    updateTempfile (file, response) {
      this.tempfile = response
    },
    resetTempfile () {
      this.tempfile = null
    },
    loadJob () {
      this.loading = true
      this.$http.get(this.context.route + '/jobs/' + this.job_id + '/').then(response => {
        this.job = response.data
        this.loading = false
      })
    },
    downloadTemplate () {
      const link = document.createElement('a')
      let headers = ['Purchase Order Number', 'Retailer ID', 'Cancellation Reason Code']

      if (this.selectedAction === 'cancel_orders_po_number') {
        link.download = 'CancelEntireOrdersByPONumber'
      } else if (this.selectedAction === 'cancel__order_lines_by_sku') {
        headers = ['Purchase Order Number', 'Retailer ID', 'SKU', 'Quantities', 'Cancellation Reason Code']
        link.download = 'CancelSpecificOrderLinesBySKU'
      } else {
        headers = ['SKU', 'Cancellation Reason Code']
        link.download = 'CancelAllOrderLinesOfSpecificSKU'
      }
      const csvContent = headers.join(',')
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
      link.href = URL.createObjectURL(blob)
      link.click()
    }
  }
}
</script>
