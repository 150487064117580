<template>
  <div v-if="!loading && feedDetail !== null && areFileFieldsPopulated" class="mb-lg">
    <div>
      Ruleset Failure Report:
      <DownloadFileAs label="Download" :cloudFileName="this.fileFields.ruleset_failure_report"
          :originalFileName="`${feedDetail.id}-error-items`" />
    </div>
    <div>
        Ruleset Error Report:
        <DownloadFileAs label="Download"
            :cloudFileName="this.fileFields.ruleset_error_report"
            :originalFileName="`${feedDetail.id}-failed-error-details`" />
    </div>
  </div>
  <div v-else class="mb-lg">
    Generating report - please wait for results.
    <a href="#" @click.prevent="loadJobDetails" class="has-text-weight-normal is-size-7 has-text-info">(Refresh)</a>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['context']),
    areFileFieldsPopulated () {
      return Object.values(this.fileFields).every(value => value !== null)
    }
  },
  data () {
    return {
      loading: true,
      feedDetail: null,
      fileFields: {
        ruleset_failure_report: null,
        ruleset_error_report: null
      }
    }
  },
  methods: {
    loadCompatibilityReport () {
      this.loading = true
      const endpoint = this.context.route + '/proposals/' + this.proposal.id + '/compatibility-rulesets-failure-reports/'
      this.$http.get(endpoint).then(response => {
        if (response.data.id) {
          const existingQuery = this.$route.query
          this.$router.replace({ name: 'catalog.submit.new', query: { ...existingQuery, compatibility_id: response.data.id } })
          this.loadJobDetails()
        }
      }).finally(() => {
        this.loading = false
      })
    },
    loadJobDetails () {
      const id = this.$route.query.compatibility_id
      const jobEndpoint = this.context.route + '/jobs/' + id + '/'
      this.$http.get(jobEndpoint).then(response => {
        this.feedDetail = response.data
        const { metadata } = this.feedDetail
        if (metadata && metadata.length > 0) {
          const keysToProcess = ['ruleset_failure_report', 'ruleset_error_report']
          metadata.forEach(item => {
            if (keysToProcess.includes(item.name)) {
              this.fileFields[item.name] = item.value
            }
          })
        }
      }).finally(() => {
        this.loading = false
      })
    }
  },
  mounted () {
    const compatibilityId = this.$route.query.compatibility_id
    // The compatibility-rulesets-failure-reports is supposed to create a new job every time we hit it.
    // The idea is to call it once and then fetch the job id from its response and keep querying with that job id
    if (compatibilityId) {
      this.loadJobDetails()
    } else {
      this.loadCompatibilityReport()
    }
  },
  props: ['proposal', 'ruleset']
}
</script>
