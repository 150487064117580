<template>
  <form @submit.prevent="update" novalidate autocomplete="off">
    <div class="columns">
      <div class="column is-one-fifth">
        <p class="has-text-weight-bold is-size-6 mb-sm">Key Details</p>
        <p class="has-text-grey">Update key information about your business.</p>
      </div>
      <div class="column">
        <div class="has-background-white rounded-xl">
          <div class="pa-lg pb-xl">
            <!-- Loading  -->
            <div v-if="loading">
              <div class="pa-md" v-for="index in 5" :key="index">
                <div class="placeholder placeholder-title"></div>
                <div class="placeholder placeholder-sentence" style="width:50%"></div>
              </div>
            </div>
            <!-- Loaded -->
            <div v-else>
              <div class="pb-xl border-b mb-lg">
                <b-field
                  label="Name"
                  :type="('name' in errors) ? 'is-danger' : ''"
                  :message="('name' in errors) ? errors['name'][0] : ''"
                  expanded>
                  <b-input
                    autocomplete="off"
                    type="text"
                    name="name"
                    v-model="name"
                    @input="isDirty = true"
                  ></b-input>
                </b-field>
                <b-field
                  label="Website URL"
                  :type="('website' in errors) ? 'is-danger' : ''"
                  :message="('website' in errors) ? errors['website'][0] : ''"
                  expanded>
                  <b-input
                    autocomplete="off"
                    placeholder="e.g. https://example.com"
                    type="text"
                    name="website"
                    v-model="website"
                    @input="isDirty = true"
                  ></b-input>
                </b-field>
              </div>
              <!-- Contacts -->
              <div>
                <div class="mb-lg">
                  <label class="label">Connections Manager</label>
                  <p class="has-text-grey mb-sm is-size-7">
                    The Connections Manager is the key contact for
                    all vendor business inquiries.
                  </p>
                  <b-field
                    :type="('connections_manager' in errors) ? 'is-danger' : ''"
                    :message="('connections_manager' in errors) ? errors['name'][0] : ''"
                    expanded>
                    <b-select
                      v-model="connectionsManager"
                      placeholder="Select User"
                      expanded
                      @input="isDirty = true">
                      <option v-for="user in users" :key="user.id" :value="user.id">
                        {{ user.first_name }} {{ user.last_name }}
                      </option>
                    </b-select>
                  </b-field>
                </div>
                <div class="mb-lg">
                  <label class="label">Merchandising Manager</label>
                  <p class="has-text-grey mb-sm is-size-7">
                    The Merchandising Manager is the key contact for
                    all product & inventory inquiries.
                  </p>
                  <b-field
                    :type="('merchandising_manager' in errors) ? 'is-danger' : ''"
                    :message="('merchandising_manager' in errors) ? errors['merchandising_manager'][0] : ''"
                    expanded>
                    <b-select
                      v-model="merchandisingManager"
                      placeholder="Select User"
                      expanded
                      @input="isDirty = true">
                      <option v-for="user in users" :key="user.id" :value="user.id">
                        {{ user.first_name }} {{ user.last_name }}
                      </option>
                    </b-select>
                  </b-field>
                </div>
                <div>
                  <label class="label">Transactions Manager</label>
                  <p class="has-text-grey mb-sm is-size-7">
                    The Transactions Manager is the key contact for
                    all order & fullfillment inquiries.
                  </p>
                  <b-field
                    :type="('shipping_manager' in errors) ? 'is-danger' : ''"
                    :message="('shipping_manager' in errors) ? errors['shipping_manager'][0] : ''"
                    expanded>
                    <b-select
                      v-model="transactionsManager"
                      placeholder="Select User"
                      expanded
                      @input="isDirty = true">
                      <option v-for="user in users" :key="user.id" :value="user.id">
                        {{ user.first_name }} {{ user.last_name }}
                      </option>
                    </b-select>
                  </b-field>
                </div>
              </div>
            </div>
          </div>
          <div class="border-t">
            <div class="columns is-vcentered is-marginless">
              <div class="column">
                <div class="px-sm has-text-grey-light">
                  <i v-if="isDirty">Click 'Update' to save your changes</i>
                </div>
              </div>
              <div class="column is-narrow">
                <div class="px-sm">
                  <b-button native-type="submit" type="is-primary rounded-lg" :disabled="updating || !isDirty">Update</b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  computed: {
    ...mapGetters(['context']),
    loading () {
      return this.loadingUsers || this.partner === null
    },
    endpoint () {
      return this.context.route + '/'
    },
    json () {
      const json = {
        name: this.name,
        website: this.website
      }
      if (this.connectionsManager !== null) {
        json.connections_manager = { id: this.connectionsManager }
      }
      if (this.merchandisingManager !== null) {
        json.merchandising_manager = { id: this.merchandisingManager }
      }
      if (this.transactionsManager !== null) {
        if (this.context.type === 'vendor') {
          json.shipping_manager = { id: this.transactionsManager }
        } else {
          json.transaction_manager = { id: this.transactionsManager }
        }
      }
      return json
    }
  },
  data () {
    return {
      isDirty: false,
      name: null,
      website: null,
      errors: [],
      updating: false,
      loadingUsers: true,
      connectionsManager: null,
      merchandisingManager: null,
      transactionsManager: null
    }
  },
  methods: {
    loadUsers () {
      this.loadingUsers = true
      this.$http.get(this.context.route + '/users/?mini=1&pagination=0&order_by=first_name').then(response => {
        this.users = response.data.results
        this.loadingUsers = false
      })
    },
    update () {
      this.updating = true
      this.$http.patch(this.endpoint, this.json).then(response => {
        this.name = response.data.name
        this.website = response.data.website
        this.$store.dispatch('updateContextInfo', {
          name: response.data.name,
          website: response.data.website
        })
        this.isDirty = false
        this.updating = false
        this.$buefy.toast.open({ type: 'is-success', message: 'Settings updated!' })
      }).catch(error => {
        this.updating = false
        if (error.response && error.response.status === 400) {
          this.errors = error.response.data
        }
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      })
    }
  },
  mounted () {
    this.loadUsers()
    this.name = this.context.partner.name
    this.website = this.context.partner.website
  },
  props: ['partner'],
  watch: {
    partner (partner) {
      if (partner.connections_manager !== null) {
        this.connectionsManager = partner.connections_manager.id
      }
      if (partner.merchandising_manager !== null) {
        this.merchandisingManager = partner.merchandising_manager.id
      }
      if ('shipping_manager' in partner && partner.shipping_manager !== null) {
        this.transactionsManager = partner.shipping_manager.id
      }
      if ('transaction_manager' in partner && partner.transaction_manager !== null) {
        this.transactionsManager = partner.transaction_manager.id
      }
    }
  }
}
</script>
