<template>
  <div>
    <filter-toolbar
      :hasChanges="hasChanges"
      :appliedFilters="appliedFilters"
      :allowFilters="true"
      :allowSearch="true"
      @search="search"
      searchPlaceholder="Search by identifier..."
      @update="update"
      @clear="clear">
      <boolean-filter
        v-model="filters.facets.is_rejected"
        :filter="filterOptions.rejected"
        @input="change"
      />
    </filter-toolbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import * as filterOptions from '../../filters/proposalProducts.js'
import BooleanFilter from '@/components/Filters/BooleanFilter'
// import DateFilter from '@/components/Filters/DateFilter'
// import RemoteFacetFilter from '@/components/Filters/RemoteFacetFilter'
import FilterToolbar from '@/components/Filters/FilterToolbar'

export default {
  components: {
    BooleanFilter,
    // DateFilter,
    // RemoteFacetFilter,
    FilterToolbar
  },
  data () {
    return {
      hasChanges: false,
      filters: null,
      // filters
      filterOptions: filterOptions
    }
  },
  computed: {
    ...mapGetters(['context'])
  },
  methods: {
    change () {
      this.hasChanges = true
    },
    search (searchQuery) {
      this.filters.search = {
        parameter: 'identifier',
        query: searchQuery
      }
      this.$emit('filter', this.filters)
    },
    update () {
      this.hasChanges = false
      this.filters.preset = 'custom'
      this.$emit('filter', this.filters)
    },
    clear () {
      this.filters = {
        preset: 'all',
        facets: {},
        search: {},
        timestamps: {}
      }
      this.$emit('filter', this.filters)
    }
  },
  props: {
    appliedFilters: {
      type: Object,
      required: true
    }
  },
  watch: {
    appliedFilters: {
      deep: true,
      immediate: true,
      handler (filters) {
        this.filters = filters
      }
    }
  }
}
</script>
