import dates from '@/assets/js/dates'

export const modules = {
  label: 'Job',
  key: 'module_name',
  icon: 'pulse',
  tooltip: 'Limit results based on the job/module name',
  options: [{
    value: 'import_feed',
    label: 'Import Products'
  }, {
    value: 'process_shipment_file_upload',
    label: 'Import Shipments'
  }, {
    value: 'process_invoice_file_upload',
    label: 'Import Invoices'
  }, {
    value: 'ingest_810',
    label: 'EDI: Import Invoice (810)'
  }, {
    value: 'ingest_856',
    label: 'EDI: Import ASN (856)'
  }, {
    value: 'ingest_846',
    label: 'EDI: Inventory (846)'
  }, {
    value: 'ingest_855',
    label: 'EDI: Acknowledgement (855)'
  }, {
    value: 'push_product',
    label: 'Publish Products'
  }, {
    value: 'products/update',
    label: 'Shopify: Product Updated'
  }, {
    value: 'orders/paid',
    label: 'Shopify: Order Import (Paid)'
  }, {
    value: 'orders/create',
    label: 'Shopify: Order Import (Created)'
  }, {
    value: 'fulfillments/create',
    label: 'Shopify: Import Fulfillment (Created)'
  }, {
    value: 'fulfillments/update',
    label: 'Shopify: Import Fulfillment (Updated)'
  }, {
    value: 'ALLOCATION_CREATE_SHIP',
    label: 'OMS: Order Import'
  }, {
    value: 'pull_products',
    label: 'Pull Products'
  }, {
    value: 'push_inventory',
    label: 'Push Inventory'
  }, {
    value: 'push_order',
    label: 'Push Order'
  }, {
    value: 'orders/cancelled',
    label: 'Order Cancelled by Supplier'
  }]
}

export const hasErrors = {
  label: 'Has Errors?',
  key: 'has_errors',
  icon: 'pulse',
  tooltip: 'This is a tooltip',
  options: [{
    value: 1,
    label: 'Yes'
  }, {
    value: 0,
    label: 'No'
  }]
}

export const createdAt = {
  label: 'Created At',
  key: 'created_at',
  icon: 'pulse',
  tooltip: 'Limit results based on when the job was created.',
  options: dates
}
