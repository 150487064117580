<template>
  <div class="section">
    <div class="container">
    <div class="mb-lg">
      <h4 class="is-size-4 has-text-centered">Compliance Scorecard</h4>
    </div>
    <!-- Toolbar -->
    <div class="mb-lg">
      <!-- Controls -->
      <div class="columns">
        <div class="column">
          <div class="columns" style="align-items:center">
            <div class="column is-narrow">
              <p class="has-text-weight-bold">Sort By</p>
            </div>
            <div class="column">
              <div class="field">
                <b-select
                  size="is-medium"
                  v-model="sort"
                  expanded
                  @input="updateSort"
                  :disabled="!this.loading && !this.selectedMonth"
                >
                  <option v-if="context.isMerchant()" value="rank">Rank (Asc)</option>
                  <option v-if="context.isMerchant()" value="-rank">Rank (Desc)</option>
                  <option value="brand_name">{{ merchantOrSupplier }} Name (Asc)</option>
                  <option value="-brand_name">{{ merchantOrSupplier }} Name (Desc)</option>
                  <option value="brand_id">Id (Asc)</option>
                  <option value="-brand_id">Id (Desc)</option>
                  <option value="score">Score (Asc)</option>
                  <option value="-score">Score (Desc)</option>
                </b-select>
              </div>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="columns" style="align-items:center">
            <div class="column">
              <div class="field is-grouped">
                <input
                  v-model="searchQuery"
                  class="input is-medium"
                  type="text"
                  :placeholder="getSearchPlaceholder"
                  v-on:keyup.enter="getSelectedMonthsData"
                  :disabled="!this.loading && !this.selectedMonth"
                >
                <p class="control">
                  <b-button
                    size="is-medium is-primary"
                    icon-right="magnify"
                    :disabled="this.loading || searchQuery === null || searchQuery === ''"
                    @click="getSelectedMonthsData"
                  />
                  <b-button
                    size="is-medium"
                    icon-right="refresh"
                    :disabled="this.loading || ! formCanReset"
                    @click="reset"
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="column is-one-quarter">
          <div class="field">
            <b-select
              size="is-medium"
              v-model="selectedMonth"
              @input="getSelectedMonthsData" expanded
              :disabled="!this.loading && !this.selectedMonth"
            >
              <option
                v-for="period in periods"
                :value="period"
                :key="period.id"
                selected
              >
                {{ period.label }}
              </option>
            </b-select>
          </div>
        </div>
      </div>
    </div>

    <!-- Results Loaded -->
    <div v-if="!this.loading && this.supplierResults.length > 0">
      <div class="mb-md">
        <div class="mb-xs has-background-white pa-md rounded-lg">
          <!-- Results -->
          <div>
            <div class="mb-md">
              <b-table
                class="is-size-7"
                :data="this.supplierResults"
                :checked-rows.sync="selected"
                :mobile-cards="false"
                :loading="this.loading"
                hoverable>
                <template>
                  <!-- ID -->
                  <b-table-column
                    label="ID"
                    header-class="nowrap"
                    cell-class="nowrap has-text-grey-light"
                    v-slot="props"
                    width="64"
                    centered>
                    <p v-if="context.isMerchant()">{{ props.row.brand.id }}</p>
                    <p v-else>{{ props.row.retailer.id }}</p>
                  </b-table-column>
                  <!-- Supplier or Merchant -->
                  <b-table-column
                    :label="merchantOrSupplier"
                    header-class="nowrap"
                    cell-class="nowrap"
                    style="cursor: pointer"
                    v-slot="props">
                    <router-link
                      :to="{
                        name: 'reports.compliance.score.detail',
                        params: {
                          id: context.isMerchant() ? props.row.brand.id : props.row.retailer.id,
                          connection_id: props.row.connection_id,
                          report_id: reportId
                        },
                        query: { period_end: selectedMonthsEndTimestamp }
                      }"
                    >
                      <p v-if="context.isMerchant()">{{ props.row.brand.name }}</p>
                      <p v-else>{{ props.row.retailer.name }}</p>
                    </router-link>
                  </b-table-column>
                  <!-- Score and Change -->
                  <b-table-column
                    label="Score & Change"
                    header-class="nowrap"
                    cell-class="nowrap"
                    v-slot="props"
                    width="128">
                    <ScoreTagChange :score="props.row.score" :scoreChange="props.row.score_change" />
                  </b-table-column>
                   <!-- Award -->
                  <b-table-column
                    v-if="context.isMerchant()"
                    label="Award"
                    header-class="nowrap"
                    cell-class="nowrap"
                    width="128"
                    centered
                    v-slot="props">
                    <Award :score="props.row.score" />
                  </b-table-column>
                  <!-- Rank and Change -->
                  <b-table-column
                    v-if="context.isMerchant()"
                    label="Rank & Change"
                    header-class="nowrap"
                    cell-class="nowrap"
                    width="128"
                    centered
                    v-slot="props">
                    <RankTagChange :rank="props.row.rank" :rankChange="props.row.rank_change"/>
                  </b-table-column>
                  <!-- Actions -->
                  <b-table-column
                    label="Actions"
                    header-class="nowrap"
                    cell-class="nowrap has-text-weight-bold"
                    centered
                    width="128"
                    v-slot="props">
                    <router-link
                      :to="{
                        name: 'reports.compliance.score.detail',
                        params: {
                          id: context.isMerchant() ? props.row.brand.id : props.row.retailer.id,
                          connection_id: props.row.connection_id,
                          report_id: reportId
                        },
                        query: { period_end: selectedMonthsEndTimestamp }
                      }"
                    ><b-icon
                        style="cursor: pointer"
                        size="is-small"
                        icon="eye-outline"
                        class="mr-sm"/>
                    </router-link>
                  </b-table-column>
                </template>
                <template #empty>
                  <div class="py-xxl has-text-grey has-text-centered">
                    {{ (this.loading) ? "Loading data..." : 'No suppliers found!' }}
                  </div>
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </div>
      <b-pagination
        :total="total"
        :current.sync="currentPage"
        :rounded="true"
        :per-page="perPage"
        @change="updatePage">
      </b-pagination>
    </div>
    <!-- Results Loaded (Empty) -->
    <div v-else-if="!this.loading && this.supplierResults.length === 0" class="has-background-white pa-xxl rounded-lg has-text-centered">
      <p class="is-size-5">No results</p>
      <p class="has-text-grey">Please check your filters and try again.</p>
    </div>
    <!-- Results Loading -->
    <div v-else>
      <div v-for="index in 5" :key="index" class="has-background-white px-lg py-md rounded-lg mb-xs">
        <div class="placeholder placeholder-sentence mb-md" style="width:75%"></div>
        <div class="placeholder placeholder-sentence" style="width:50%"></div>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ScoreTagChange from '@/components/Presenters/Compliance/ScoreTagChange'
import RankTagChange from '@/components/Presenters/Compliance/RankTagChange'
import Award from '@/components/Presenters/Compliance/Award'

export default {
  components: {
    ScoreTagChange,
    RankTagChange,
    Award
  },
  computed: {
    ...mapGetters(['context']),
    formCanReset () {
      return (this.searchQuery !== null)
    },
    merchantOrSupplier () {
      return this.context.isMerchant() ? 'Supplier' : 'Merchant'
    },
    getSearchPlaceholder () {
      return this.context.isMerchant() ? 'Search for Suppliers' : 'Search for Merchants'
    },
    queryString () {
      const qs = {
        params: {
          is_public: 1,
          limit: this.perPage,
          page: this.currentPage,
          order_by: this.sort
        }
      }
      // attach optional query string
      if (this.searchQuery !== null) qs.params.search = this.searchQuery
      return qs
    }
  },
  data () {
    return {
      loading: true,
      searchQuery: null,
      sort: '-score',
      selected: [],
      currentPage: 1,
      perPage: 12,
      selectedMonth: null,
      selectedMonthsEndTimestamp: null,
      reportId: null,
      periods: [],
      supplierResults: [],
      total: null
    }
  },
  methods: {
    getAvailableCompliancePeriods () {
      this.loading = true
      const request = this.context.route + '/compliance-reports/'
      this.$http.get(request, this.queryString).then(response => {
        if (response.data.results) {
          this.reportId = response.data.results[0].id
          const periods = response.data.results[0].periods
          if (periods.length) {
            periods.forEach(period => {
              period.label = this.$dates.utc(period.end).local().format('MMMM YYYY')
              this.periods.unshift(period)
            })
            // fetch current month's data
            this.selectedMonth = this.periods[0]
            if (this.selectedMonth) {
              this.getSelectedMonthsData()
            } else { this.loading = false }
          }
        } else { this.loading = false }
      })
    },
    getSelectedMonthsData () {
      this.loading = true

      this.$http.get(this.context.route + '/compliance-reports/' + this.reportId + '/periods/' + this.selectedMonth.id + '/results/'
        , this.queryString
      ).then(response => {
        this.supplierResults = response.data.results
        this.total = response.data.count
        this.selectedMonthsEndTimestamp = this.$dates(this.selectedMonth.end).unix()
      }).finally(() => {
        this.loading = false
      })
    },
    reset () {
      this.searchQuery = null
      this.currentPage = 1
      this.sort = '-score'
      this.getSelectedMonthsData()
    },
    cacheQuery () {
      sessionStorage.setItem('compliance', JSON.stringify(this.queryString))
    },
    updateSort () {
      this.currentPage = 1
      this.getSelectedMonthsData()
    },
    updatePage (value) {
      this.currentPage = value
      this.getSelectedMonthsData()
    }
  },
  mounted () {
    const querySet = JSON.parse(sessionStorage.getItem('compliance'))
    if (querySet !== null) {
      this.filters = querySet.filters
      if (querySet.orderBy !== this.defaults.orderBy) {
        this.orderBy = querySet.orderBy
      }
      if (querySet.currentPage !== this.defaults.currentPage) {
        this.currentPage = querySet.currentPage
      }
    }
    this.getAvailableCompliancePeriods()
  }
}
</script>
