export const integrations = {
  label: 'Integration Type',
  key: 'integration_type',
  icon: 'lan',
  tooltip: 'This is a tooltip',
  options: [{
    value: 'console',
    label: 'Web'
  }, {
    value: 'edi',
    label: 'EDI'
  }, {
    value: 'storefront',
    label: 'Store Front'
  }, {
    value: 'api',
    label: 'API'
  }, {
    value: 'shopify',
    label: 'Shopify'
  }]
}

export const status = {
  label: 'Status',
  key: 'status',
  icon: 'pulse',
  tooltip: 'Filter connections by status',
  options: [{
    value: 'active',
    label: 'Active'
  }, {
    value: 'pending',
    label: 'Pending'
  }, {
    value: 'onboarding',
    label: 'Onboarding'
  }, {
    value: 'suspended',
    label: 'Suspended'
  }]
}
